import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { ErrorView } from '@/components/ErrorView';
import { loginWithMagicLink } from '@/actions/login';
import { useNavigate } from 'react-router-dom';

export function ReedemMagicLinkPage() {
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const { token } = useParams()
    useEffect(() => {
        loginWithMagicLink(token).then(result => {
            navigate(`/dashboard`)
        }).catch(e => {
            console.error(e)
            setError(e)
        })
    }, [token])
    return (
        <form method="POST">
            {error &&
                <ErrorView />
            }
        </form>
    )
}

export default ReedemMagicLinkPage;
import React from 'react';

import i18n from '@/i18n';

import { GenericList } from '@/components/GenericList';

export function UsersPage() {
  return <UsersView />
}

export function UsersView({
  q = '',
  viewType
}) {
  return (
    <GenericList
      queryKey={['users']}
      path={`/users`}
      addLabel={i18n.t('add-user')}
      filterPlaceholder={i18n.t('filter-users')}
      canSelectViewType={false}
      avatar={true}
      canChangeSortBy={false}
      viewType="list"
      columns={[
        {
          id: 'email',
          name: i18n.t('email'),
          render: ({ node }) => node.email
        },
        {
          id: 'username',
          name: i18n.t('username'),
          render: ({ node }) => node.username
        }
      ]}
      heading={i18n.t('users')}
      addUrl={`/dashboard/admin/user/add`}
      baseUrl={`/dashboard/admin/user`}
    />
  )
}

export default UsersPage
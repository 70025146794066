import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/joy';
import { X } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { getCurrentUser } from '@/actions/login';

export function NotAuthorizedPage() {
    const navigate = useNavigate()
    useEffect(() => {

        getCurrentUser().then((data) => {
            if (data.code) {
              navigate(`/accounts/login`)
              
            }
        }).catch(e => {
            navigate(`/accounts/login`)
        })
    }, [])
    return (
        <Box sx={{ position: 'fixed', left: 0, top: 0, gap: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <X size={128} />
            <Typography level="h1" element="h1">Sorry, it seems you don't belong to this label</Typography>
            <Typography>If this is wrong, please contact the label support</Typography>
            <Button variant="solid" as={Link} to="/accounts/logout">Log out</Button>
        </Box>
    )
}

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom'; 

import { createMagicLink, loginWithUsernameAndPassword } from '@/actions/login';

import { getAccountUrl } from '@/utils'

import i18n from '@/i18n';
import { Button, Alert, Box } from '@mui/joy'
import { TextField } from '@/components/InputField';
import { Warning } from '@phosphor-icons/react';
import { Check } from '@phosphor-icons/react';
import { useLoadSession } from '@/contexts';

export function LoginWithPasswordPage() {
  const navigate = useNavigate()
  const [loggingIn, setLoggingIn] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const loadSession = useLoadSession()
  const [loggedIn, setLoggedIn] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoggingIn(true)
    localStorage.setItem('email', email);
    loginWithUsernameAndPassword(email, password).then((data) => {
      setError(null)
      setLoggingIn(false)
      navigate(`/dashboard`);
    }).catch(e => {
      console.error(e)
      setLoggingIn(false)
      setError(e)
    })
  }
  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box sx={{ padding: 1 }}>
        <Box style={{ display: 'flex', gap: 10, width: '100%', alignItems: 'stretch', flexDirection: "column"}}>
          {error &&
            <Alert variant="filled" color="danger">
              <Warning size={28} />
              {i18n.t('invalid-username-or-password')}
            </Alert>
          }
          {loggedIn &&
            <Alert variant="filled" color="success">
              <Check size={28} />
              {i18n.t('successfully-logged-in')}
            </Alert>
          }
          <TextField placeholder={i18n.t("email")} onChange={(event) => setEmail(event.target.value)} name="email" variant="standard" label={i18n.t("email")} required={true} />
          <TextField placeholder={i18n.t("password")} onChange={(event) => setPassword(event.target.value)} name="password" type="password" variant="standard" label={i18n.t("password")} required={true} />
          <Button loading={loggingIn} type="submit" variant="solid" color="primary">{loggingIn ? i18n.t('loading') : i18n.t('login')}</Button>
          <Button variant="outlined" onClick={() => navigate(`/accounts/login`)}>{i18n.t('login-with-link-instead')}</Button>
        </Box>
      </Box>
    </form>
  )
}

export default LoginWithPasswordPage;
import React from 'react';
import { Alert, Box, Button, Container, Typography } from '@mui/joy';
import { TextField } from '@/components/InputField';
import validbarcode from "barcode-validator";
import i18n from '@/i18n';
import { useState } from 'react';
import { AudioField } from '@/components/AudioField';

import { SelectRelatedNodes } from '@/components/SelectRelatedNodes';

import { SelectRelatedNode } from '@/components/SelectRelatedNode';
import { Loading } from '@/components/Loading';
import { useParams } from 'react-router-dom';
import { Check, Lock } from '@phosphor-icons/react';
import { db, setDoc, updateDoc, doc } from '@/client';
import { useNavigate } from 'react-router-dom';
import { useResource } from '@/hooks';
import { useEffect } from 'react';
import { ErrorView } from '@/components/ErrorView';
import { useSnackbar } from 'notistack';
import { ArtworkField } from '@/components/ArtworkField';
import { uploadCoverArtToRelease } from '@/actions/login';
import { HBox } from '@/components/HBox';
import { submitReleaseForReview } from '@/actions/aloader';
import { FeatureCard } from '@/components/FeatureCard';

export default function EditReleasePage() {
  const { releaseId } = useParams()
  return (
    <React.Suspense fallback={<Loading />}>
      <EditReleaseView releaseId={releaseId} />
    </React.Suspense>
  )
}

export function ReleaseForm({
  values: defaultValues,
  submitLabel,
  release,
  onSubmit = () => console.error('onSubmit not implemented'),
  onValuesChange = () => console.warn('onValuesChange not implemented')
}) {

  const [errors, setErrors] = useState([])

  const getFieldErrors = (fieldId) => errors?.filter(i => i.field === fieldId)

  const validateForm = () => {
    let errors = []

    if (!values?.image && !values?.image_url) {
      errors.push({
        field: 'image',
        description: i18n.t('release-needs-an-artwork'),
        type: i18n.t('missing')
      })
    }

    if (!values?.primary_genre) {
      errors.push({
        field: 'primary_genre',
        description: i18n.t('release-must-have-a-genre'),
        type: 'missing'
      })
    }

    if (!values?.name) {
      errors.push({
        field: 'name',
        description: i18n.t('release-must-have-a-name'),
        type: i18n.t('empty')
      })
    }

    if (values?.upc?.length > 0 && !validbarcode(values.upc)) {
      errors.push({
        field: 'upc',
        description: i18n.t('invalid-upc-code'),
        type: i18n.t('invalid')
      })
    }
  
    if (values?.released) {

    } else {
      errors.push({
        field: 'released',
        description: i18n.t('we-need-a-release-date-for-this-release'),
        type: 'missing'
      })
    }

    if (values?.tracks?.length > 0) {

    } else {
      errors.push({
        field: 'tracks',
        description: i18n.t('release-is-missing-tracks'),
        type: 'missing'
      })
    }

    if (values?.release_artists?.length > 0) {

    } else {
      errors.push({
        field: 'release_artists',
        description: i18n.t('missing-release-artists'),
        type: 'missing'
      })
    }
    setErrors(errors)
    return errors?.length < 1
  }

  const [values, setValues] = useState(defaultValues)

  useEffect(() => {
    setValues(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    onValuesChange(values)
  }, [values])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validateForm()) {
      onSubmit(values)
    }
    return false    
  }

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <Box>
        {values?.locked && (
          <FeatureCard
            sx={{ marginTop: 2, p: 1, marginBottom: 2 }}
            Icon={Lock}
            name={i18n.t('release-locked.description')}
            description={i18n.t('release-locked.heading')}
          /> 
        )}
        {errors?.map(error => (
          <Alert color="danger">{error.description}</Alert>
        ))}
        <ArtworkField
          readOnly={values?.locked}
          label={i18n.t('artwork')}
          errors={getFieldErrors('image')}
          imageUrl={values?.image_url}
          onChange={(value) => setValues({ ...values, image: value })}
        />
        <TextField
          name="name"
          readOnly={values?.locked}
          errors={getFieldErrors('name')}
          defaultValue={values?.name}
          label={i18n.t('name')}
          placeholder={i18n.t("enter-release-name")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value  })}
        />
        <TextField
          name="version"
          readOnly={values?.locked}
          label={i18n.t('version')}
          defaultValue={values?.version}
          placeholder={i18n.t("enter-release-version")}
          onChange={(event) => {
            return setValues({ ...values, ['version']: event.target.value })
          }}
          info={i18n.t('version-field-example')}
        />
        <TextField
          name="released"
          readOnly={values?.locked}
          errors={getFieldErrors('released')}
          type="date"
          label={i18n.t('release-date')}
          defaultValue={values?.released?.split('T')[0]}
          placeholder={i18n.t("enter-release-date")}
          onChange={(event) => {
            return setValues({ ...values, released: event.target.value })
          }}
        />
        <TextField
          name="upc"
          readOnly={values?.locked}
          errors={getFieldErrors('upc')}
          label={i18n.t('upc')}
          defaultValue={values?.upc}
          placeholder={i18n.t("enter-upc-code")}
          onChange={(event) => setValues({ ...values, [event.target.name]: event.target.value })}
        />
        <SelectRelatedNode
          label={i18n.t('genre')}
          readOnly={values?.locked}
          errors={getFieldErrors('primary_genre')}
          ownerNodeId={null}
          nodeType="genre"
          ownerNodeType="release"
          addLabel={i18n.t("add-recording-genre")}
          placeholder={i18n.t('enter-recording-genre')}
          allLabel={i18n.t('all-genres')}
          ownLabel={i18n.t('release-s-genres')}
          node={values?.primary_genre}
          onChange={(primary_genre) => setValues({ ...values, primary_genre })}
        />
        <SelectRelatedNodes
          readOnly={values?.locked}
          errors={getFieldErrors('tracks')}
          label={i18n.t('tracklist')}
          ownerNodeId={null}
          nodeType="recording"
          ownerNodeType="release"
          addLabel={"add-track"}
          allLabel={i18n.t('all-recordings')}
          ownLabel={i18n.t('release-s-tracks')}
          nodes={values?.tracks ?? []}
          onChange={(tracks) => setValues({ ...values, tracks })}
        />
        <SelectRelatedNodes
          readOnly={values?.locked}
          label={i18n.t('artists')}
          errors={getFieldErrors('release_artists')}
          ownerNodeId={null}
          attributes={[
            {
              id: 'primary',
              type: 'bool',
              label: i18n.t('primary'),
              onLabel: i18n.t('primary'),
              offLabel: i18n.t('secondary')
            }
          ]}
          nodeType="artist"
          ownerNodeType="release"
          addLabel={"add-artist"}
          allLabel={i18n.t('all-artists')}
          ownLabel={i18n.t('release-s-artists')}
          nodes={values?.release_artists ?? []}
          onChange={(release_artists) => setValues({ ...values, release_artists })}
        />
        <HBox>
          <Button type="submit" disabled={values?.locked} startDecorator={<Check />} variant="solid">{submitLabel}</Button>
        </HBox>
      </Box> 
    </form>
  )
}

export function EditReleaseView({
  releaseId
}) {
  const [saving, setSaving] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [values, setValues] = useState(null)

  const { data: release, loading, error, isError } = useResource({
    queryKey: ['release', releaseId],
    path: `/release/${releaseId}`
  })

  const handleSubmit = (values) => {
    try {
      const image = values.image
      delete values["image_url"]
      setDoc(doc(db, 'releases', releaseId), values).then(() => {
        if (image) {
          uploadCoverArtToRelease(releaseId, image).then(() => {
            enqueueSnackbar('Release successfully saved')
          })
        } else {
          enqueueSnackbar('Release successfully saved')
        }
      })
    } catch (e) {
      console.error(e)
    }
    return false
  } 

  useEffect(() => {
    if (release) {
      setValues({
        ...release
      })
    }
  }, [release])

  const handleDistributeClick = event => {
    event.preventDefault()
    submitReleaseForReview(releaseId).then(() => {
      enqueueSnackbar(i18n.t('sent-for-distribution'))
    }).catch(e => {
      alert('Error')
    })
  }

  if (loading) {
    return <Loading />
  }

  console.log(error, isError)
  return (
    <Container>
      <h1>{i18n.t('edit-release')}</h1>
      <ReleaseForm
        onSubmit={handleSubmit}
        release={release}
        values={values}
        submitLabel={i18n.t('save-release')}
      />
      <Button onClick={handleDistributeClick} disabled={values?.locked} startDecorator={<Check />} variant="solid">{i18n.t('distribute')}</Button>
    </Container>
  )   
}
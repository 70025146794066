import React, { useEffect } from 'react';

import moment from 'moment/moment';

import { useLabel } from '@/contexts'; 
import { Box, Typography } from '@mui/joy';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@/actions/login';

export default function AccountsLayout({ children }) {
    const label = useLabel()
    const navigate = useNavigate()
    
    useEffect(() => {
        getCurrentUser().then((data) => {
            if (!data.code) { 
              navigate(`/dashboard`)
            }
        }).catch(e => {
            console.error(e)
        })
    }, [])
  
    return (
      <ws-block>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ flex: 1 }}></Box>
          <img src={label?.logotypeUrl} width="300pt" />
          <Typography level="h1" component="h1">Distribution</Typography>
          <Box style={{ flex: 1 }}></Box>
          <Outlet />
          <Box sx={{ flex: 1 }}></Box>
          <small style={{ margin: 10, opacity: 0.5 }}>Copyright &copy; {moment().format('YYYY')} {label?.name}. All Rights Reserved.</small>
        </Box>
      </ws-block>
    )
}
import React from 'react';

import i18n from '@/i18n'

import OtpInput from 'react-otp-input';
import { Box, Button, Input, Typography } from '@mui/joy';
import ReactAuthCodeInput from 'react-auth-code-input';
import { useState } from 'react';
import { loginWithOTP } from '@/actions/login';

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import OTPInput from 'react-otp-input';

export function LoginLinkPage() {
    const email = localStorage.getItem('email')
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [validating, setValidating] = useState(false)

    const handleSubmit = event => {
        event.preventDefault()

        submit();
        return false
    }
    const formRef = useRef(null)
    
    const submit = () => {
        if (password.length === 6) {
            setValidating(true)
            loginWithOTP(password).then(result => {
                navigate(`/dashboard`)
                setValidating(false)
                setError(false)
            }).catch(e => {
                console.error(e)
                setError(e)
                setValidating(false)
                setPassword('')
            })
        }
    }

    useEffect(() => {
        console.log(password)
        if (password.length === 6) {
            formRef.current.requestSubmit()
        }
    }, [password])
 
    const handleRenderSeparator = (index) => {
        if (index === 2) {
            return <span>-</span>
        }
    }

    const handlePaste = (event) => {
        const data = event.clipboardData.getData('text');
        setPassword(data.replace(/[^A-Z0-9 ]/g, ""))
    }

    return (
        <form ref={formRef} method="POST" onSubmit={handleSubmit}>
            <Box sx={{ padding: 1 }}>
                <Box style={{ display: 'flex', textAlign: 'center', gap: 10, width: '100%', alignItems: 'center', flexDirection: "column"}}>
                    <Typography level="h1">Check your email for a code</Typography>
                    <Typography>We've sent a 6-character code to <b>{email}</b>. The code expires shortly, so please enter it soon.</Typography>
                    <OTPInput shouldAutoFocus={true} onPaste={handlePaste} numInputs={6} value={password} renderInput={(props) => <Input sx={{ margin: 1}} slotProps={{ input: {...props} }} />} renderSeparator={handleRenderSeparator} disabled={validating} onChange={setPassword} type="number" fields={6} />
                    {error && (
                        <Typography color="danger">{i18n.t('invalid-one-time-password')}</Typography>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Button variant="outlined" onClick={() => window.open('https://mail.google.com/mail/u/0/')}>{i18n.t('open-in-service', { service: 'GMail' })}</Button>
                        <Button variant="outlined" onClick={() => window.open('https://outlook.live.com/mail/0/inbox')}>{i18n.t('open-in-service', { service: 'Outlook' })}</Button>
                    </Box>
                </Box>
            </Box>
        </form>
    )
}

export default LoginLinkPage;